
.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}

.wrong_password {
    color:red;
    margin-top: 2px;
}

.password_changed {
    color:green;
    margin-top: 2px;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 300px;
}

.auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
}