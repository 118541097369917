.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0 1px 6px, rgba(0, 0, 0, 0.075) 0 1px 4px;
    background-color: lightgreen;
}

.ant-upload-list {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    line-height: 1.5715;
}
.ant-upload-list::before {
    display: table;
    content: '';
}
.ant-upload-list::after {
    display: table;
    clear: both;
    content: '';
}
.ant-upload-list-item {
    position: relative;
    height: 350px;
    width: 350px;
    margin-top: 8px;
    font-size: 14px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.ant-upload-list-picture-card-container {
    display: inline-block;
    width: 350px;
    height: 180px;
    margin: 0 8px 8px 0;
    vertical-align: top;
}


.sortableItem {
    width: 350px;
    height: 370px;
    margin: 0 8px 8px 0;
    cursor: grab;
    border: 1px solid;
}



/*.fields {
    margin-top: 80px;
    margin-left: 5px;
}*/

/*.sortableItem > div {
}*/





